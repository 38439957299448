.add {
    &-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 30px;
    }
    &-item {
        width: 100%;
        aspect-ratio: 2 / 1;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0px 0px 10px 10px #00000010;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        transition: 0.2s;
        &:hover {
            transform: scale(1.05)
        }
        &-icon {
            &::before {
                font-size: 50px;
                color: #000;
            }
            margin-bottom: 30px;
        }
        
    }
}