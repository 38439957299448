.products {
    background-color: #fff;
    border-radius: 25px;
    h4 {
        margin: 20px;
    }
    &-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: min-content;
        position: relative;
        padding: 30px 15px; 
        min-height: 400px;
        
    }
    &-row {
        display: grid;
        grid-template-columns: 5% 20% 10% 30% 10% 15% 10%;
        border: 1px solid #DFDFDF; 
        border-left: none;
        border-right: none;
        align-items: center;
        background-color: #fff;
        &:first-child {
            border-top: none;
        }
        &_sticky {
            position: sticky;
            top: 0px;
            background-color: #f1f1f1;
            div {
                padding: 10px 0;
                font-size: 16px;
            }
        }
    }
    &-col {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        border-left: 1px solid #DFDFDF;
        padding: 5px;
        font-size: 12px;
        gap: 5px ;
        &:first-child {
            border: none;
        }
        &-icon {
            max-width: 20px;
        }
        &-img {
            height: 60px;
            img {
                max-height: 100%;
            }
        }
    }
    &__img {
        display: block;
        min-width: 90px;
        width: 90px;
        height: 90px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 20px;
        }
    }
}
