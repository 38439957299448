.login {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    &-content {
        max-width: 400px;
        width: 100%;
        height: auto;
        padding: 50px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        border-radius: 15px;
        box-shadow: 0px 0px 10px 0px #00000020;
        h1 {
            font-size: 26px;
            text-align: center;
        }
        img {
            width: 40px;
        }
        input {
            width: 100%;
            outline: none;
            font-size: 16px;
            padding: 10px 5px;
            border: none;
            border-bottom: 1px solid #222222;
            font-weight: 500;
        }
    }
    &-pass {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        span {
            position: absolute;
            right: 0;
            &::before {
                font-size: 16px;
            }
        }
        input {
            padding-right: 20px;
        }
    }
    &-btn {
        width: 100%;
        background-image: linear-gradient(to right, #77A1D3 0%, #79CBCA  51%, #77A1D3  100%);
        margin: 10px;
        padding: 15px 45px;
        text-align: center;
        text-transform: uppercase;
        transition: 0.5s;
        background-size: 200% auto;
        color: white;            
        box-shadow: 0 0 20px #eee;
        border:none;
        border-radius: 10px;
        display: block;
        
        &:hover {
            background-position: right center; /* change the direction of the change here */
            color: #fff;
            text-decoration: none;
        }
    }
}

