.btn {
    width: 100%;
    height: 60px;
    background-color: #00000000;
    font-size: 14px;
    font-weight: 600;
    transition: 0.2s;
    border: 2px solid #000;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    &:disabled {
        filter: opacity(0.3);
        pointer-events: none;
    }
    &_small {
        height: 40px;
    }
    &:hover {
        background-color: #000;
        color: #fff;
        
    }
}
.cancel-btn {
    color: var(--main--color);
    border-color: var(--main--color);
    &:hover {
        background-color: var(--main--color);
        color: #fff;
        border: 2px solid var(--main--color);
    }
}
.send-btn {
    color: var(--third--color);
    border-color: var(--third--color);
    &:hover {
        background-color: var(--third--color);
        color: #fff;
    }
}

.edit-btn {
    min-width: 35px;
    min-height: 35px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px #00000020;
    border: none;
    background-size: 17px;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 30px; 
    cursor: pointer;
    transition: transform 0.1s;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
        transform: scale(1.05);
    }
    &::before {
        position: absolute;
        font-size: 16px;
        color: #000;
    }
}

.delete-btn {
    content: '\e905';
    background-color: var(--main--color);
}

.swap-btn {
    content: '\e90e';
    width: 30px;
    height: 30px;
    background-size: 15px;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
}

.close-btn {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00000000;
    border: none;
    border-radius: 100px;
    cursor: pointer;
    &::before {
        content: '\e904';
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: 30px;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.back-btn {
    background-color: var(--main--color);
    color: #fff;
    padding: 10px 30px;
    padding-left: 20px;
    border-radius: 30px;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
    transition: all 0.3s;
    &-icon {
        transform: rotate(180deg);
    }
    &:hover {
        color: #fff;
        transform: scale(1.05);
    }
}
