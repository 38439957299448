.nav {
    width: 300px;
    max-height: calc(100vh - 45px);
    background-color: #fff;
    position: sticky;
    top: 25px;
    padding: 15px;
    box-shadow: 0px 0px 15px 4px #00000010;
    border-radius: 25px;
    overflow: hidden;
    transition: width 0.3s;
    ul {
        li {
            padding: 0;
            margin: 0;
            margin-bottom: 10px;
            a {    
                width: 100%;
                padding: 0;
                height: 60px;
                color: var(--second--color);
                display: grid;
                grid-template-columns: 60px 1fr;
                align-items: center;
                gap: 25px;
                border-radius: 15px;
                transition: color 0.3s, background-color 0.3s;
                transition: 0.2s;
                &:hover {
                    color: var(--main--color);
                    background-color: var(--main--color-15);
                }
                &.active {
                    color: var(--main--color);
                    background-color: var(--main--color-15);
                }
                span {
                    transition: .1s;
                }
            }
        }
    }
    &-header {
        display: grid;
        grid-template-columns: 60px 160px 60px;
        grid-template-rows: 60px;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        margin-bottom: 25px;
        overflow: hidden;
        position: relative;
    }
    &-icon {
        padding: 0;
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
            font-size: 26px;
        }
    }
    &-logo {
        width: 50px;
    }
    &-close {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        cursor: pointer;
        background-color: #00000000;
        border: none;
        &-btn {
            width: 30px;
            height: 3px;
            background-color: #000;
            border-radius: 100px;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                width: 15px;
                height: 3px;
                background-color: #000;
                border-radius: 100px;
                top: -7px;
                left: 0;
            }
            &::after {
                content: '';
                position: absolute;
                width: 15px;
                height: 3px;
                background-color: #000;
                border-radius: 100px;
                bottom: -7px;
                right: 0;
            }
        }
    }
    
}

@media (max-width: 1440px) {
    .nav {
        width: 230px;
        ul {
            li {
                a {    
                    font-size: 12px;
                    gap: 5px;
                    
                }
            }
        }
        &-header {
            display: grid;
            grid-template-columns: min-content  min-content  min-content;
            grid-template-rows: 60px;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            margin-bottom: 25px;
            overflow: hidden;
            position: relative;
        }
        &-icon {
            padding: 0;
            margin: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            span {
                font-size: 20px;
            }
        }
    }
    
}