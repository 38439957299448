.editor {
    h2 {
        margin-bottom: 20px;
        display: inline;
        
    }
    &-add-btn {
        display: inline;
        background-color: var(--main--color);
        color: #fff;
        padding: 10px 50px;
        border-radius: 10px;
        transition: 0.2s all;
        &:hover {
            transform: scale(1.05);
            color: #fff;
        }
    }
    &-breadcrumbs {
        display: flex;
        gap: 5px;
        margin-bottom: 20px;
        align-items: center;
        &-link {
            position: relative;
            font-weight: 500;
        }
        &-icon {
            font-size: 10px;
        }
    }
    &-grid {
        display: grid;
        grid-template-columns: 70% 1fr; 
        gap: 30px;
    }
    &-form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: min-content;
        grid-auto-flow: row;
        gap: 20px;
        margin-top: 20px;
        padding-bottom: 100px;
        &-cropper {
            grid-column: span 2;
        }
        &-btns {
            display: flex;
            grid-column: span 2;
            gap: 30px;
        }
        &-full {
            grid-column: span 2;
            
        }
        label {
            width: 100%;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            gap: 6px;
            input,
            select,
            textarea {
                border: 2px solid #b8b8b8;
                padding: 10px 15px;
                font-size: 16px;
                background-color: transparent;
                border-radius: 10px;
            }
            
            textarea {
                max-height: 300px;
                min-height: 200px;
                width: 100%; 
            }
            &:has(textarea) {
                grid-column: span 2;
            }
        }
        
    }
    &-img {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        img {
            width: 100%;
            border-radius: 20px;
            aspect-ratio: 1 / 1;
            object-fit: contain;
            background-color: #fff;
        }
        span {
            font-size: 24px;
        }
    }
}