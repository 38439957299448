.crop-wrapper {
    position: relative;
    border-radius: 20px;
}

.crop-container {
    width: 100%;
    height: 600px;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    padding-top: 10px;
    &.editing {
        height: auto;
    }
    &-btn {
        display: inline;
        max-width: 200px;
    }
    &-edit-area {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        border-radius: 20px;
        background-color: #fff;
    }
}

.reactEasyCrop_Container {
    max-height: 400px;
    height: 100%;
    background-color: #fff;
}

.reactEasyCrop_CropAreaGrid {
    &::before,
    &::after {
        border-color: #d0d0d0!important;
    }
}

.controls {
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    padding: 20px;
    position: absolute;
    bottom: 0px; 
    background-color:#fff;
    gap: 30px;
    height: 200px;
    &-btns {
        display: flex;
        gap: 30px;
        float: right;
        width: 100%;
        button {
            min-width: 150px;
        }
    }
}
.slider {
}


.cropped-image {
    display: block;
    margin-top: 20px;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.input-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: auto;
    margin-bottom: 20px;
    border: 2px dashed #bababa;
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 3 / 1;
    cursor: pointer;
    &-icon {
        font-size: 60px;
    }
    &__input {   
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
    &__text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
        width: 100%;
        height: 100%;
        color: #000;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        gap: 20px;
        p {
            font-size: 14px;
            font-weight: 400;
        }
    }
}

