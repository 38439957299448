.subcategories {
    background-color: #fff;
    padding: 25px 15px;
    border-radius: 25px;
    h3 {
        margin-bottom: 10px;
        text-align: center;
        background-color: var(--main--color);
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        font-size: 16px;
    }
    &-grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-columns: max-content;
        
    }
    &-item {
        padding: 10px;
        margin: 3px;
        border-radius: 15px;
        transition: all .2s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        span {
            font-size: 14px;
            color: var(--second--color);
            transition: all .2s;
        }
        &.active {
            background-color: var(--main--color-15);
            span {
                color: var(--main--color);
            }
        }
        &:hover {
            background-color: var(--main--color-15);
            span {
                color: var(--main--color);
            }
        }
    }
}