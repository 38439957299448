.app-grid {
    min-height: 100vh;
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: 25px;
    transition: grid-template-columns 0.3s;
    padding: 25px;
    padding-bottom: 0;
    position: relative;
}

@media (max-width: 1440px) {
    .app-grid {
        gap: 15px;
        
    }
}