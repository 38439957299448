.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    animation: fadeInLoader 0.3s;
}

.dot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #008ae6;
    box-shadow: 0 5px 10px rgba(255, 255, 255, 0.01);
    animation: animate 1s ease-in-out infinite alternate;
}

.dot:nth-child(1){
    animation-delay: -0.25s;
}

.dot:nth-child(2){
    background: #e60000;
    animation-delay: -0.5s;
}

.dot:nth-child(3){
    background: #ffcc00;
    animation-delay: -0.75s;
}

.dot:nth-child(4){
    background: #008800;
    animation-delay: -1s;
}

@keyframes animate {
    0% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(5px);
    }
}

@keyframes fadeInLoader {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}