@import './variables.scss';
@import './titles.scss';
@import './buttons.scss';
@import './icons.scss';

* {
    font-family: var(--main--font);
    font-weight: 600;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-style: normal;
}

body {
    background-color: var(--body--color);
}

ul,
li,
dl {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
    display: inline-block;
    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

p {
    margin: 0;
    padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}