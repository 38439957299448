.statistics {
    &-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
    }
    &-item {
        width: 100%;
        height: 130px;
        background-color: #fff;
        border-radius: 30px;
        padding: 20px;
        &-header {
            display: flex;
            align-items: center;
            span {
                color: #797979;
            }
        }
    }
    &-icon {
        display: block;
        width: 40px;
        height: 40px;
        background-color: var(--third--color);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        margin-right: 10px;
        span {
            font-size: 18px;
            color: #fff;
        }
    }
    &-count {
        margin-left: 50px;
        font-size: 40px;
        color: var(--second--color);
        img {
            max-width: 30px;
        }
    }
}