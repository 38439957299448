.catalog {
    &-grid {
        display: grid;
        grid-template-columns: 70% 30%;
        gap: 15px;
    }
    &-categories {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}