.categories {
    &-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, 100px);
        gap: 10px;
    }
    &-item {
        min-width: 100%;
        border-radius: 20px;
        display: flex;
        background-color: #ffffff;
        position: relative;
        text-decoration: none;
        box-shadow: var(--box--shadow);
        overflow: hidden;
        background-size: 100%;
        border: 2px solid #00000000;
        aspect-ratio: 1 / 1;
        cursor: pointer;
        transition: transform .1s;
        &:hover {
            transform: scale(1.05);
        }
        &:active {
            transform: scale(1);
        }
        &__edit-btn {
            width: 100%;
        }
        &-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        h3 {
            font-size: 12px;
            margin: 13px;
            pointer-events: none;
            font-weight: 500;
        }
        img {
            position: absolute;
            bottom: -10%;
            right: -10%;
            width: 65%;
            height: 65%;
            border-radius: 100px;
            z-index: 1;
            object-fit: cover;
            object-position: center;
            pointer-events: none;
        }
        &.active {
            border: 2px solid var(--main--color);
        }
    }
}