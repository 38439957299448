@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?ovayxc');
    src:  url('../fonts/icomoon.eot?ovayxc#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?ovayxc') format('truetype'),
    url('../fonts/icomoon.woff?ovayxc') format('woff'),
    url('../fonts/icomoon.svg?ovayxc#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-eye-off:before {
    content: "\e915";
}
.icon-eye:before {
    content: "\e916";
}
.icon-add:before {
    content: "\e907";
}
.icon-edit:before {
    content: "\e914";
    color: #09244b;
}
.icon-svgviewer-output-9:before {
    content: "\e90f";
}
.icon-search:before {
    content: "\e90c";
}
.icon-message:before {
    content: "\e903";
}
.icon-banners:before {
    content: "\e902";
}
.icon-catalog:before {
    content: "\e910";
}
.icon-dashboard:before {
    content: "\e911";
}
.icon-reviews:before {
    content: "\e912";
}
.icon-vacancies:before {
    content: "\e913";
}
.icon-categories:before {
    content: "\e906";
}
.icon-arrow:before {
    content: "\e900";
}
.icon-upload:before {
    content: "\e901";
}
.icon-close:before {
    content: "\e904";
}
.icon-delete:before {
    content: "\e905";
    color: #fff;
}
.icon-heart-red:before {
    content: "\e908";
    color: #e00;
}
.icon-heart:before {
    content: "\e90a";
}
.icon-link:before {
    content: "\e909";
    color: #fff;
}
.icon-products:before {
    content: "\e90b";
    color: #fff;
}
.icon-subcategories:before {
    content: "\e90d";
    color: #fff;
}
.icon-swap-lines:before {
    content: "\e90e";
    color: #758ca3;
}
