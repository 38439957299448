.header {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    &-title {
        display: flex;
        align-items: end;
        span {
            background-color: var(--main--color);
            color: #fff;
            padding: 2px 5px;
            font-size: 12px;
            border-radius: 100px;
            margin-left: 3px;
        }
    }
    &-btns {
        display: flex;
        gap: 35px;
        align-items: center;
        button {
            width: 30px;
            height: 30px;
            background-color: #00000000;
            border: none;
            cursor: pointer;
            span {
                font-size: 28px;
            }
        }
        a {
            padding: 12px 40px;
            background-color: var(--main--color);
            color: #fff;
            font-size: 16px;
            border-radius: 20px;
            transition: transform .2s;
            &:hover {
                color: #fff;
                transform: scale(1.05);
            }
            span {
                font-size: 14px;
                margin-left: 8px;
            }
        }
    }
}